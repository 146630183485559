import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Product.css';
import productImage from '../Assets/pexels-karolina-grabowska-4498135.jpg';
import backgroundImage from '../Assets/pexels-shvets-production-7203788.jpg';

const Products = () => {
    const navigate = useNavigate();
    const [isMinimized, setIsMinimized] = useState(false);

    const handleButtonClick = () => {
        navigate('/contact');
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position > 5) { // Adjust 50 to control when the banner starts minimizing
            setIsMinimized(true);
        } else {
            setIsMinimized(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="productPage">
            <div className='productPage-container'>
                <div 
                    className='banner-one' 
                    style={{ 
                        height: isMinimized ? '50vh' : '100vh', // Minimized height
                        transition: 'height 0.3s ease' 
                    }}
                >
                    <img src={backgroundImage} alt='Banner Background'></img>
                    <h1>Our Products</h1>
                </div>

                <section className='banner-two'>
                    <div className="product-item">
                        <div className='product-item-image'>
                            <img src={productImage} alt='Product'></img>
                        </div>
                        <div className='product-item-text'>
                            <h2>Corrugated Boxes</h2>
                            <p>
                                Our corrugated boxes are made from eco-friendly materials and are designed to provide maximum protection for your products. They are durable, lightweight, and customizable to fit your specific needs.
                            </p>
                            <ul>
                                <li> 100% Ecofriendly </li>
                                <li> Higher structural integrity </li>
                                <li> We offer customizable options too </li>
                            </ul>
                            <div className='button-container'>
                                <button className='add-to-cart-button' onClick={handleButtonClick}>Get a quotation</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Products;

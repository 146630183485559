import React, { useState, useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';

import '../styles/Contacts.css';

const Contacts = () => {
    const [state, handleSubmit] = useForm("xldrbgbz");
    const [formState, setFormState] = useState({ name: '', email: '', message: '' });

    useEffect(() => {
        if (state.succeeded) {
            alert("Form submitted successfully!");
            setFormState({ name: '', email: '', message: '' }); // Reset form fields
        }
    }, [state.succeeded]); // Only run effect when state.succeeded changes

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <div className='contactPage'>
            <div className='contactPage-container'>
                
                <div className='headText-container'>
                    <section className='headText'>
                        <h1>Hello, here to help</h1>
                    </section>
                </div>

                <div className='mainContent'>
                    <div className='mainContent-section1'>
                        <section>
                            <p>Ask for a quotation or any other query, we are happy to answer it 😊</p>
                            <form onSubmit={handleSubmit} className='contactForm'>
                                <label htmlFor="name">Name</label>
                                <input 
                                    id="name" 
                                    type="text" 
                                    name="name"
                                    value={formState.name}
                                    onChange={handleChange}
                                    required 
                                />
                                <ValidationError prefix="Name" field="name" errors={state.errors} />

                                <label htmlFor="email">Email Address</label>
                                <input 
                                    id="email" 
                                    type="email" 
                                    name="email"
                                    value={formState.email}
                                    onChange={handleChange}
                                    required 
                                />
                                <ValidationError prefix="Email" field="email" errors={state.errors} />
                                
                                <label htmlFor="message">Message</label>
                                <textarea 
                                    id="message" 
                                    name="message"
                                    value={formState.message}
                                    onChange={handleChange}
                                    required 
                                />
                                <ValidationError prefix="Message" field="message" errors={state.errors} />
                                
                                <button type="submit" disabled={state.submitting}>Submit</button>
                            </form>
                        </section>
                    </div>

                    <div className='mainContent-section2'>
                        <div className='newsLetter'>
                            <h2>Join our newsletter</h2>
                            <p>Be the first to know about our latest products, exclusive offers, and eco-friendly tips. Receive exclusive content, special promotions, and the latest news about our eco-friendly packaging solutions.</p>
                            <form className='newLetterForm'>
                                <label htmlFor='newsletter-email'>Email Address:</label>
                                <input 
                                    type='email' 
                                    id='newsletter-email' 
                                    name='newsletter-email' 
                                    required
                                />
                                <button type='submit'>Sign up</button>
                            </form>
                        </div>

                        <div className="detailInfo">
                            <div>
                                <p><span>Legal:</span></p>
                                <p><a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
                                <p><a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                                <p><a href="/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a></p>
                            </div>
                            <div>
                                <p><span>Alternatively contact us at:</span></p>
                                <p>huskNpack@titan.mail</p>
                                <p>+91 494848608900</p>
                                <p>Address: fniwyf vaf ofyo vaufvoovyouyvfoufoyvfoufo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;

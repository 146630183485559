import React from 'react';
import '../styles/Productcard.css';

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <div className="product-image">
      <img src={product.imageUrl} alt={product.name} className="product-image" />
      </div>
      <div className="product-description">
      <h3 className="product-name">{product.name}</h3>
        <p className="product-details">
          Product Description: asdbff iv eufvefyvpeyvpe fvey f v-afefrfeftg eofvvflufvofyo ftvo8v8uf[peyvoefve8e vt ewfefwefuyge feevoyvycvgoy 8y8y 8ybiu bcpcyc pcbp8cvypyd
        </p>
        <div className="product-stock">
          {/* <div className="stock-bar">
            <div className="stock-fill" style={{ width: '75%' }} />
          </div> */}
          {/* <div className="stock-text">Stock: 75/100</div> */}
        </div>
        <div className="buy-button"><a href='/product'>BUY</a></div>
      </div>
    </div>
  );
};

export default ProductCard;

import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/Home.css'; // Import CSS file for Home page styles
import ProductCard from '../components/ProductCard';
import plate from  "../Assets/product image crutelery plate.webp" ;
import box from "../Assets/product image rectangular box packaging.webp";
import takeoutBox from "../Assets/product image takeout with handle.jpg";
import address from "../Assets/office address.jpeg";

const Home = () => {
  const products = [  {
    id: 1,
    name: 'Coir CardBoards',
    description: 'Durable and eco-friendly coir cardboard for packaging.',
    imageUrl: box
  },
  {
    id: 2,
    name: 'Coir TakeOut Box',
    description: 'Sustainable packaging options for a greener planet.',
    imageUrl: takeoutBox
  },
  {
    id: 3,
    name: 'Coir Plates',
    description: 'Biodegradable boxes for various packaging needs.',
    imageUrl: plate
  }
];

  return (
    <div className='home'>
      <div className='home-container'>
        <div className='hero'>
          
          <div className='hero-content-text'>
            <h1>Naturally strong, Sustainably crafted.</h1>
            <p>Packaging that protects your products and our planet.</p>
            <Link to="/product" className='btn-primary'>Explore Products</Link>
          </div>
        
          {/* <div className="hero-content-image">

          </div> */}
        </div>
        
        <section className='features'>
          <h2>Our Features</h2>
          <div className='feature-items'>
            <div className='feature-item'>
              <h3>Sustainability</h3>
              <p>Eco-friendly materials that reduce waste.</p>
            </div>
            <div className='feature-item'>
              <h3>Quality</h3>
              <p>Durable and high-quality packaging solutions.</p>
            </div>
            <div className='feature-item'>
              <h3>Customizable</h3>
              <p>Tailored solutions to fit your specific needs.</p>
            </div>
          </div>
        </section>
        
        <div className='about-container'>
          <div className='about-content-image'>
          <img src={address} alt="Office address" className="office-image" />
          </div>

          <div className='about-content-text'>
            <p> Your go-to source for sustainable and innovative packaging solutions. We specialize in creating high-quality packaging cardboard made from coconut coir, offering an eco-friendly alternative to traditional materials.
                Our coconut coir packaging is durable, biodegradable, and designed to meet the demands of modern businesses looking to reduce their environmental impact. By choosing our products, you’re not only opting for superior packaging but also supporting sustainability and environmental responsibility.
                Discover our range of products and join us in making a positive impact on the planet, one package at a time.</p>
          </div>

        </div>

        <div className='featured-product-container'>
          <h3>Featured Product</h3>
          <div className="product-list">
            {products.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </div>
        </div>

        <section className='cta'>
          <h2>Ready to make the switch?</h2>
          <p>Contact us today to learn more about our eco-friendly packaging options.</p>
          <a href="/contact" className='btn-secondary'>Get in Touch</a>
        </section>
      </div>
    </div>
  );
};

export default Home;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../styles/Navbar.css";

export class Navbar extends Component {
  render() {
    return (
      <div className='navbar'>
        <div className='navbar-container'>
            
            <div className='navbar-logo'>
                <h2>HUSK'<span>N</span>PACK</h2>
            </div>
            
            <div className='navbar-menu'>
                <div className='navbar-links'>
                    <div><Link to="/" >Home</Link></div>
                    <div><Link to="/product" >Product</Link></div>
                    <div><Link to="/blog" >Blog</Link></div>
                    <div><Link to="/contact" >Contact</Link></div>
                </div>
            </div>`

        </div>
    </div>
    );
  }
}

export default Navbar;
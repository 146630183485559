import React from "react";
import "../styles/Blog.css";


const Blog = () =>{
    const blogPosts = [
        {
            id: 1,
            title: "Why Eco-Friendly Packaging is the Future",
            date: "July 25, 2024",
            summary: "The shift towards eco-friendly packaging is inevitable. Learn why sustainable packaging is essential and how it benefits businesses and the planet.",
            link: "https://www.linkedin.com/in/your-profile"
        },
        {
            id: 2,
            title: "The Journey of Our Sustainable Packaging",
            date: "July 20, 2024",
            summary: "Discover the process behind our eco-friendly packaging solutions, from sourcing coconut husk to the final product.",
            link: "https://www.linkedin.com/in/your-profile"
        },
        {
            id: 3,
            title: "Top 5 Benefits of Using Biodegradable Packaging",
            date: "July 10, 2024",
            summary: "Find out the top 5 benefits of switching to biodegradable packaging today.",
            link: "https://www.linkedin.com/in/your-profile"
        }
    ];


    return (
      <div className="blogPage">
        <div className="blogPage-container">
            {blogPosts.map(post=>(
                <div className="blogPost" key={post.id}>
                    <h2>{post.title}</h2>
                    <p className="blogDate">{post.date}</p>
                    <p>{post.summary}</p>
                    <a href={post.link} target="_blank" rel="noopener noreferrer" className="blogLink">Read More</a>
                </div>
            ))}
        </div>
      </div>  
    );
}

export default Blog;
